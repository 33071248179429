<template>
  <el-dialog
    :title="type=='edit' ? '编辑角色' : '新增角色'"
    :visible.sync="value"
    width="30%" 
    @open="dialogOpen"
    :before-close="dialogClose">
    <el-form ref="form" :model="form" :rules="rules" label-width="auto">
      <el-form-item label="角色名称" prop="roleName">
        <el-input v-model="form.roleName"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="roleRemark">
        <el-input v-model="form.roleRemark" type="textarea" :rows="3"></el-input>
      </el-form-item>
    </el-form>
    <div>
      <el-button type="primary" v-debounce="onSubmit" :disabled="disabled">确 定</el-button>
      <el-button @click="dialogClose">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>

import { createRole, updateRole} from '@/api/setting'
export default {
  props:['value', 'editItem','type'],
  data() {
    return {
      form: {
        roleName:'',                   //角色名称
        roleRemark:'',                    // 备注
        roleId:'',                        //主键
      },
      rules: {
          roleName:[
            {required: true, message: '角色名称不可为空', trigger: 'blur'},
          ],
      },
      disabled:false
      
    }
  },
  methods: {
    dialogOpen(){
      if(this.type=="edit"){
        this.form.roleName=this.editItem.roleName
        this.form.roleRemark=this.editItem.roleRemark
        this.form.roleId=this.editItem.roleId
      }else{
        this.form.roleName=''
        this.form.roleRemark=''
        delete this.form.roleId
      }
    },
    dialogClose(){
      console.log(123123123)
      this.$refs['form'].resetFields();
      this.$emit('input',false)
    },
    onSubmit() {
      if(!this.$validate('form')){
        return
      }
      this.disabled=true
      if(this.type=="edit"){//修改
        updateRole(this.form).then(res=>{
          this.$message.success('修改成功')
          this.dialogClose()
          this.disabled=false
        }).catch(err=>{
          console.log(err)
          this.$message.error('修改失败')
          this.disabled=false
        })
      }else{//新增
        createRole(this.form).then(res=>{
          this.$message.success('添加成功')
          this.dialogClose()
          this.disabled=false
        }).catch(err=>{
          console.log(err)
          this.$message.error('添加失败')
          this.disabled=false
        })
      }
    }
  }
}
</script>