<!--
 * @Author: your name
 * @Date: 2020-12-11 10:19:32
 * @LastEditTime: 2021-02-05 17:55:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \scweb\src\views\service\outlets\index.vue
-->
<template>
<div>
    <el-container >
            <el-aside width="220px" height="500px" style="border-right:1px solid #f1f1f1">
                    <el-row>
                        <el-col :span="24">
                            <el-button type="primary" size="mini" @click="roleAdd">新增</el-button>
                            <el-button type="primary" size="mini" @click="roleEdit">编辑</el-button>
                            <el-button type="danger" size="mini" @click="roleDeleted">删除</el-button>
                        </el-col>
                    </el-row>
                    <el-menu
                    style="border-right:none!important;"
                    class="el-menu-vertical-demo">
                    <div  v-for="(item,index) in roleList" :key="index">
                        <el-row class="tac" style="height:100%;">
                            <el-col :span="24">
                                <el-menu-item :index="index+''" @click.native="menuClick(item)">
                                    <span style="font-size:18px;" slot="title" v-text="item.roleName"></span>
                                </el-menu-item>
                            </el-col>
                        </el-row>
                    </div>
                    </el-menu>
            </el-aside>
            <el-container style="flex-direction: column;">
                <el-tree
                    ref="tree"
                    :data="menuTree"
                    show-checkbox
                    node-key="menuId"
                    :default-expanded-keys="defaultShow"
                    :props="defaultProps">
                </el-tree>
                <el-button style="width:200px;margin:40px;"  type="primary" v-debounce="saveMenu">保存</el-button>
            </el-container>
    </el-container>
    <Add v-model="dialogAdd" :editItem="editItem" :type="selType" @input="getRoleAll"></Add>
</div>

    
</template>
<script>
import { getRoleAll, getRoleMenu, getMenuList, setRoleMenu, deleteRole } from '@/api/setting'
import Add from './components/add.vue'
import { getCookies } from '@/utils/utils'

export default {
    name:'account',
    components:{
        Add
    },
    data(){
        return {
            roleId:'',              //角色id
            defaultProps: {
                children: 'child',
                label: 'label'
            },
            menuTree:[],
            roleList:[],
            defaultShow:[],
            dialogAdd:false,
            editItem:{},
            selType:'',
        }
    },
    mounted(){
        this.getList();
        this.getRoleAll();
    },
    methods:{
        menuClick(item) {
            let that = this;
            that.roleId = item.roleId;
            this.editItem=Object.assign({},item)
            this.$refs.tree.setCheckedKeys([]);
            getRoleMenu(item.roleId)
            .then((response)=>{
                let list = []
                response.data.forEach(item=>{
                    if(item.child && item.child.length){
                        item.child.forEach(item2=>{
                            list.push(item2)
                        })
                    }else{
                        list.push(item)
                    }
                })
                that.$refs.tree.setCheckedNodes(list);
            });
        },
        
        getRoleAll(){
            let that = this;
            getRoleAll()
            .then((response)=>{
                that.roleList = response.data;
                // that.menuClick(response.data[0])
            });
        },  
        
        roleAdd(){
            this.dialogAdd = true
            this.selType = 'add'
        },  
        roleEdit(){
            if(!this.roleId){
                this.$message.warning('请先选择需要修改的角色')
                return
            }
            this.dialogAdd = true
            this.selType = 'edit'
        },  
        roleDeleted(){
            if(!this.roleId){
                this.$message.warning('请先选择需要删除的角色')
                return
            }
            this.$confirm(`此操作将删除角色：${this.editItem.roleName}, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteRole({roleId:this.roleId}).then(res=>{
                    this.$refs.tree.setCheckedNodes([]);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getRoleAll()
                }).catch(err=>{
                    this.$message.error('删除失败')
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },  

        saveMenu(){ 
            let that = this;
            let menuIdArrs = this.$refs.tree.getHalfCheckedKeys().concat(this.$refs.tree.getCheckedKeys());
            
            setRoleMenu({'menuIds':menuIdArrs,'roleId':that.roleId})
            .then((response)=>{
                
                that.$message({
                    title: '错误',
                    type:response.code == 200 ?'success':'error',
                    message: response.msg || response.error_msg || response.message,
                    duration: 1500
                });
            });
        },
        getList(){
            let that = this;
            getMenuList()
            .then((response) => {
                if(response.code == 200){
                   that.menuTree = response.data;
                   that.defaultShow = response.data.map(v=>v.menuId)
                   console.log('that.defaultShow',that.defaultShow)
                }else{
                    that.$message.error({
                        title: '错误',
                        message: response.msg,
                        duration: 1500
                    });
                }
                
            })
        }
    },
}
</script>
<style lang="scss" scoped>

</style>